import {
  LocalizationProvider,
  DatePicker as MUIDataPicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TranslationKey } from "@vision/common";
import dayjs, { Dayjs } from "dayjs";
import { useField } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { FormLabel } from "../FormLabel/FormLabel.js";

export type DatePickerOption = {
  fieldName: string;
  label: TranslationKey;
};

export const DatePicker = ({ fieldName, label }: DatePickerOption) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(fieldName);

  const handleDateChange = (value: Dayjs | null) => {
    if (value === null) {
      return helpers.setValue(null);
    } else if (value.isValid()) {
      return helpers.setValue(value.format("YYYY-MM-DD"));
    }
  };

  return (
    <>
      <FormLabel label={label} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MUIDataPicker<Dayjs>
          value={field.value ? dayjs(field.value, "YYYY-MM-DD") : null}
          onChange={handleDateChange}
          format="DD/MM/YYYY"
          slotProps={{
            textField: {
              error: meta.touched && Boolean(meta.error),
              helperText: meta.touched && t(meta.error as never),
              fullWidth: true,
              inputProps: {
                "data-testid": `formField-${fieldName}`,
              },
            },
          }}
        />
      </LocalizationProvider>
    </>
  );
};
