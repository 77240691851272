import {
  AvailableOrganisationUnbranded,
  OrganisationIdUnbranded,
} from "@vision/common";
import { AxiosInstance } from "axios";

export const config = {
  organisation: {
    getAvailableOrganisations: (id: OrganisationIdUnbranded) => ({
      url: `/api/organisation/${id}/available-organisations`,
      method: "get",
    }),
  },
};

export const api = (apiAxios: AxiosInstance) => {
  return {
    organisation: {
      getAvailableOrganisations: (id: OrganisationIdUnbranded) =>
        apiAxios.request<AvailableOrganisationUnbranded[]>(
          config.organisation.getAvailableOrganisations(id),
        ),
    },
  };
};
