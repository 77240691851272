import { Button, Paper, Typography, useTheme } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { TypographyI18N } from "../../index.js";

// TODO: get from config
export type IncidentFormType = "section136" | "section56" | "voluntary";

export function StartNewIncidentWorkItem(props: {
  enabledForms: IncidentFormType[];
  onNewForm: () => void;
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const enabledFormsText = props.enabledForms
    .map((enabledForm) => t(`incidentWorkItem.${enabledForm}`))
    .join(", ");

  return (
    <Paper
      sx={{
        border: "1px solid",
        borderColor: theme.palette.secondary.dark,
        borderRadius: "5px",
        padding: "2rem",
        backgroundColor: theme.palette.secondary.light,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <TypographyI18N
        align="center"
        variant="h5"
        translationKey={"incidentWorkItem.startNew"}
        sx={{ marginBottom: "0.2rem" }}
      />
      <Typography
        sx={{ marginBottom: "1.5rem" }}
        align="center"
        variant="caption"
      >
        {enabledFormsText}
      </Typography>
      <Button
        data-testid={"newFormButton"}
        onClick={props.onNewForm}
        fullWidth
        sx={{ paddingTop: "1rem", paddingBottom: "1rem", maxWidth: "20rem" }}
        variant="contained"
      >
        <TypographyI18N translationKey="incidentWorkItem.newForm" />
      </Button>
    </Paper>
  );
}
