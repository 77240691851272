import {
  IncidentWorkItemIdSchema,
  IncidentWorkItemWithContext,
  IncidentWorkItemWithContextSchema,
} from "@vision/common";
import { defer, LoaderFunctionArgs } from "react-router-dom";
import api from "../../api/index.js";
import { Deferred } from "./util.js";

export type IncidentWorkItemLoaderData = {
  incidentWorkItem: IncidentWorkItemWithContext;
};

export type IncidentWorkItemLoaderDeferredData =
  Deferred<IncidentWorkItemLoaderData>;

export const incidentWorkItemLoader = ({ params }: LoaderFunctionArgs) => {
  let id;
  try {
    id = IncidentWorkItemIdSchema.parse(params.id);
  } catch (error) {
    throw new Error(`Invalid incident ID provided. ${error}`);
  }

  const incidentWorkItem = api.incidentWorkItem
    .get(id)
    .then(async (response) => {
      if (response.status === 200) {
        const result = await IncidentWorkItemWithContextSchema.safeParseAsync(
          response.data,
        );

        if (result.success) {
          return result.data;
        } else {
          throw new Error("Invalid data format received.");
        }
      } else {
        throw new Error(
          `Failed to fetch incident work item: ${response.statusText}`,
        );
      }
    })
    .catch(() => {
      throw new Error(`Failed to load incident work item with ID: ${id}`);
    });

  return defer({
    incidentWorkItem: incidentWorkItem,
  } as IncidentWorkItemLoaderDeferredData);
};
