import React from "react";

import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import {
  AvailableOrganisationUnbranded,
  OrganisationFormBuilderField,
  OrganisationRoles,
} from "@vision/common";
import { useField } from "formik";

export type OrganisationDropdownProps<
  Form extends Record<string, unknown>,
  _Field extends keyof Form,
> = {
  fieldName: string;
  label: string | undefined;
  options: (data: Form) => AvailableOrganisationUnbranded[];
  values: Form;
  filterByRoles?: OrganisationRoles[];
};

export function OrganisationDropdown<
  Form extends Record<string, unknown>,
  Field extends keyof Form,
>({
  fieldName,
  label,
  options,
  values,
  filterByRoles,
}: OrganisationDropdownProps<Form, Field>) {
  const theme = useTheme();
  const [fieldInput] =
    useField<OrganisationFormBuilderField<Form, Field>>(fieldName);

  const filteredOptions = filterByRoles
    ? options(values).filter((option) => filterByRoles.includes(option.role))
    : options(values);

  return (
    <Box sx={{ paddingBottom: theme.spacing(3) }}>
      <FormControl fullWidth data-testid={`formField-${fieldName}`}>
        <Typography sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
          {label}
        </Typography>
        <Select
          name={fieldName}
          value={fieldInput.value}
          onChange={fieldInput.onChange}
          onBlur={fieldInput.onBlur}
          data-testid="select-dropdown"
        >
          {filteredOptions.map((option: AvailableOrganisationUnbranded) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
