import React from "react";

import { Box, TextField as MuiTextField, Typography } from "@mui/material";
import { useField } from "formik";
import { useTranslation } from "react-i18next";

export type TextFieldProps = {
  fieldName: string;
  label: string | undefined;
};

export function TextField({ fieldName, label }: TextFieldProps) {
  const { t } = useTranslation();
  const [fieldInput, fieldMeta] = useField<string>(fieldName);

  return (
    <Box data-testid={`formField-${fieldName}`}>
      <Typography sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
        {label}
      </Typography>
      <MuiTextField
        id={fieldName}
        name={fieldName}
        value={fieldInput.value}
        error={fieldMeta.touched && Boolean(fieldMeta.error)}
        helperText={
          fieldMeta.touched && fieldMeta.error && t(fieldMeta.error as never)
        }
        onChange={fieldInput.onChange}
        onBlur={fieldInput.onBlur}
        fullWidth
      />
    </Box>
  );
}
