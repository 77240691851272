import React, { useMemo, useState } from "react";

import { Box, Stack } from "@mui/material";
import {
  AvailableOrganisationArray,
  ExtendedVisionUser,
  IncidentWorkItemIdSchema,
} from "@vision/common";
import dayjs from "dayjs";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import { v4 } from "uuid";
import api from "../../api/index.js";
import { CreateIncidentWorkItemForm } from "../../forms/CreateIncidentWorkItem/CreateIncidentWorkItemForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { RootLoaderData } from "../../routing/loaders/root.js";

export type CreateIncidentWorkItemProps = {
  errorHandling: "throw" | "inline";
  user: ExtendedVisionUser;
  availableOrganisations: AvailableOrganisationArray;
};

export type CreateIncidentWorkItemPageProps = {
  errorHandling: "throw" | "inline";
};

export function CreateIncidentWorkItem({
  errorHandling,
  user,
  availableOrganisations,
}: CreateIncidentWorkItemProps) {
  const navigate = useNavigate();

  const id = useMemo(() => IncidentWorkItemIdSchema.parse(v4()), []);
  const [error, setError] = useState<string | undefined>(undefined);

  if (error !== undefined && errorHandling === "throw") {
    throw new Error(error);
  }

  return (
    <Stack
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CreateIncidentWorkItemForm
        onSubmit={async (values) => {
          try {
            const response = await api.incidentWorkItem.create(id, {
              patientForename: values.personForename,
              patientSurname: values.personSurname,
              dispatchNumber: values.dispatchNumber,
              dispatchNumberCreatedAt: dayjs(
                values.dispatchNumberCreatedAt,
              ).toDate(),
              commandUnit: values.commandUnit,
            });

            if (response.status === 204) {
              navigate(`/incident/${id}/progress`);
              return;
            } else {
              throw new Error(JSON.stringify(response.data.message));
            }
          } catch (error) {
            setError(
              "Failed to create incident work item: " +
                JSON.stringify((error as Error).message),
            );
          }
        }}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        defaultCommandUnitOrganisationId={user.sessionContext?.organisationId!}
        onCancel={() => navigate(createRoute.home())}
        availableOrganisations={availableOrganisations}
      />
      {error !== undefined && errorHandling === "inline" && <Box>{error}</Box>}
    </Stack>
  );
}

export function CreateIncidentWorkItemPage({
  errorHandling,
}: CreateIncidentWorkItemPageProps) {
  const { user, availableOrganisations } = useRouteLoaderData(
    "root",
  ) as RootLoaderData;

  return (
    <CreateIncidentWorkItem
      errorHandling={errorHandling}
      user={user}
      availableOrganisations={availableOrganisations}
    />
  );
}
