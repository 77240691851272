import React from "react";

import { Stack } from "@mui/material";
import { FormSection, IncidentWorkItemWithContext } from "@vision/common";
import { Await, useNavigate, useRouteLoaderData } from "react-router-dom";
import api from "../../api/index.js";
import {
  TriageIncidentForm,
  TriageIncidentFormSkeleton,
} from "../../forms/TriageIncident/TriageIncidentForm.js";
import { createRoute } from "../../routing/createRoute.js";
import { IncidentWorkItemLoaderDeferredData } from "../../routing/loaders/incidentWorkItem.js";
import { Error as ErrorComponent } from "../Error/Error.js";

export type TriageIncidentPageProps = Record<string, unknown>;

export function TriageIncidentPage(_props: TriageIncidentPageProps) {
  const navigate = useNavigate();
  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;
  const [error, setError] = React.useState<string | undefined>(undefined);

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    if (error !== undefined) {
      throw new Error(error);
    }

    const incidentWorkItemId = incidentWorkItem?.incidentWorkItem?.id;
    const triageForm = incidentWorkItem?.forms?.find(
      (form) => form.formType === "triageIncident",
    );
    const triageFormId = triageForm?.id;

    return (
      <Stack
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {incidentWorkItemId === undefined || triageFormId === undefined ? (
          <TriageIncidentFormSkeleton />
        ) : (
          <TriageIncidentForm
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            initialValues={triageForm?.sections?.[0]?.data as any}
            onSubmit={async (values) => {
              try {
                if (incidentWorkItemId === undefined) {
                  return;
                }

                const response = await api.incidentWorkItem.updateData(
                  incidentWorkItemId,
                  triageFormId,
                  FormSection.triageMain,
                  values,
                  true,
                );
                if (response.status === 204) {
                  navigate(
                    createRoute.incidentProgressPage(incidentWorkItemId),
                  );
                  return;
                } else {
                  setError(JSON.stringify(response.data.message));
                }
              } catch (error) {
                setError(
                  "Failed to create incident work item: " +
                    JSON.stringify((error as Error).message),
                );
              }
            }}
            onCancel={() => navigate(createRoute.home())}
          />
        )}
      </Stack>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await resolve={incidentWorkItem} errorElement={<ErrorComponent />}>
        {(resolvedData: IncidentWorkItemWithContext) => render(resolvedData)}
      </Await>
    </React.Suspense>
  );
}
