import { Box, Chip, Skeleton, useTheme } from "@mui/material";
import { IncidentWorkItemWithContext, TranslationKey } from "@vision/common";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  EmptyIncidentWorkItemCard,
  IncidentWorkItemCard,
  TypographyI18N,
} from "../../components/index.js";
import { createRoute } from "../../routing/createRoute.js";

export type IncidentListProps = {
  incidentWorkItems: IncidentWorkItemWithContext[];
  isLoading: boolean;
  mode: "myIncidents" | "transferredIncidents";
};

export const IncidentList = ({
  incidentWorkItems,
  isLoading,
  mode,
}: IncidentListProps) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    showAssignedOfficerOnMobile,
    titleKey,
    emptyKey,
    backgroundColor,
  }: {
    showAssignedOfficerOnMobile: boolean;
    titleKey: TranslationKey;
    emptyKey: TranslationKey;
    backgroundColor: string;
  } =
    mode === "myIncidents"
      ? {
          showAssignedOfficerOnMobile: false,
          titleKey: "incidentWorkItem.myIncidents",
          emptyKey: "incidentWorkItem.yourIncidentsHere",
          backgroundColor: theme.palette.warning.dark,
        }
      : {
          showAssignedOfficerOnMobile: true,
          titleKey: "incidentWorkItem.transferredIncidents",
          emptyKey: "incidentWorkItem.transferredIncidentsHere",
          backgroundColor: theme.palette.error.main,
        };

  return (
    <Box sx={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
      <Box sx={{ display: "flex" }}>
        <TypographyI18N translationKey={titleKey} />
        <Chip
          data-testid="incident-list-count"
          label={isLoading ? ".." : incidentWorkItems.length}
          size="small"
          sx={{
            backgroundColor,
            color: theme.palette.warning.contrastText,
            marginLeft: "0.5rem",
          }}
        />
      </Box>
      {isLoading ? (
        <Skeleton
          data-testid="incident-list-skeleton"
          sx={{
            padding: 0,
            width: "100%",
            [theme.breakpoints.down("md")]: {
              minHeight: "10rem",
            },
            [theme.breakpoints.up("md")]: {
              minHeight: "3rem",
            },
            transform: "unset",
          }}
        />
      ) : incidentWorkItems.length > 0 ? (
        <>
          {incidentWorkItems.map((incident) => {
            return (
              <IncidentWorkItemCard
                key={incident.incidentWorkItem.id}
                incidentWorkItem={incident}
                onViewIncident={() =>
                  navigate(
                    createRoute.incidentProgressPage(
                      incident.incidentWorkItem.id,
                    ),
                  )
                }
                showAssignedOfficerOnMobile={showAssignedOfficerOnMobile}
              />
            );
          })}
        </>
      ) : (
        <EmptyIncidentWorkItemCard title={emptyKey} />
      )}
    </Box>
  );
};
