export type VisionConfig = {
  backendUrl: string;
  environment: string;
  datadog: {
    applicationId: string;
    clientToken: string;
    disableTracing: boolean;
  };
  version: string;
};

export const config: VisionConfig = {
  environment: import.meta.env.VITE_ENVIRONMENT,
  backendUrl: import.meta.env.VITE_BACKEND_URL,
  version: import.meta.env.VITE_CODE_VERSION,
  datadog: {
    applicationId:
      import.meta.env.VITE_DATADOG_APPLICATION_ID ||
      "078efd4f-d245-4ec5-8a5f-1cb1ae35cb15",
    clientToken:
      import.meta.env.VITE_DATADOG_CLIENT_TOKEN ||
      "pube32cfb18d225feb73d4b9973adf6d838",
    disableTracing: import.meta.env.VITE_DATADOG_DISABLE_TRACING === "true",
  },
};
