import { useNotifications } from "@toolpad/core/useNotifications";

export const useToastNotifications = () => {
  const notifications = useNotifications();

  const show = (
    message: React.ReactNode,
    options?: {
      severity?: "success" | "error" | "warning" | "info";
      autoHideDuration?: number;
    },
  ) => {
    notifications.show(message, {
      severity: options?.severity,
      autoHideDuration: options?.autoHideDuration ?? 30000,
    });
  };

  return {
    show,
  };
};
