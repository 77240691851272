import { Typography, TypographyProps } from "@mui/material";
import { TranslationKey } from "@vision/common";
import React from "react";
import { useTranslation } from "react-i18next";

export type TypographyI18NProps = Omit<TypographyProps, "children"> & {
  translationKey: TranslationKey;
};

export function TypographyI18N({
  translationKey,
  ...props
}: TypographyI18NProps) {
  const { t } = useTranslation();
  return <Typography {...props}>{t(translationKey)}</Typography>;
}
