import React from "react";

import { Box, Typography, useTheme } from "@mui/material";
import { FormBuilderField } from "@vision/common";
import { FormikProps } from "formik";
import { useTranslation } from "react-i18next";
import { DatePicker } from "./DatePicker/DatePicker.js";
import { DateTimePicker } from "./DateTimePicker/DateTimePicker.js";
import { OrganisationDropdown } from "./OrganisationDropdown/OrganisationDropdown.js";
import { TextField } from "./TextField/TextField.js";

export type FieldProps<
  Form extends Record<string, unknown>,
  Field extends keyof Form & string,
> = {
  field: FormBuilderField<Form, Field>;
} & FormikProps<Form>;

export function Field<
  Form extends Record<string, unknown>,
  Field extends keyof Form & string,
>({ field, values }: FieldProps<Form, Field>) {
  const { t } = useTranslation();
  const theme = useTheme();

  // Every time the value changes, consider changing the values of dependent fields
  switch (field.type) {
    case "date":
      return (
        <Box sx={{ paddingBottom: theme.spacing(3) }}>
          <DatePicker
            fieldName={field.fieldName}
            label={field.label ? t(field.label) : undefined}
          />
        </Box>
      );

    case "date-time":
      return (
        <Box>
          <DateTimePicker
            fieldName={field.fieldName}
            dateLabel={field.dateLabel}
            timeLabel={field.timeLabel}
          />
        </Box>
      );
    case "textbox":
      return (
        <Box sx={{ paddingBottom: theme.spacing(3) }}>
          <TextField
            fieldName={field.fieldName}
            label={field.label ? t(field.label) : undefined}
          />
        </Box>
      );
    case "readonly":
      return (
        <Box sx={{ paddingBottom: theme.spacing(3) }}>
          <Typography sx={{ fontWeight: "bold", marginBottom: "0.5rem" }}>
            {t(field.label)}
          </Typography>
          <Typography>{field.textFn(values)}</Typography>
        </Box>
      );
    case "organisation":
      return (
        <OrganisationDropdown
          fieldName={field.fieldName}
          label={field.label ? t(field.label) : undefined}
          values={values}
          options={field.options}
          filterByRoles={field.filterByRoles}
        />
      );
  }
  return null;
}
