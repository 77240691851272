import { HorizontalRule } from "@mui/icons-material";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IncidentWorkItemWithContext, TranslationKey } from "@vision/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { TypographyI18N } from "../../TypographyI18N/TypographyI18N.js";
import { renderUkDateTime } from "../../util.js";

export type IncidentWorkItemCardProps = {
  incidentWorkItem: IncidentWorkItemWithContext;
  onViewIncident: () => void;
  showAssignedOfficerOnMobile: boolean;
};

function calculateDerivedIncidentStatus(
  _incidentWorkItem: IncidentWorkItemWithContext,
) {
  // TODO: more complex logic here around status of arrival/handover etc.
  // Maybe this logic happens on the server side?

  return "formInProgress";
}

function HeadingAndSubheading({
  heading,
  subheading,
}: {
  heading: TranslationKey;
  subheading: string;
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <TypographyI18N translationKey={heading} />
      </Box>
      <Box>
        <Typography sx={{ fontWeight: "bold" }}>{subheading}</Typography>
      </Box>
    </Box>
  );
}

type DesktopIncidentWorkItemCardProps = {
  typeOfIncident: string;
  createdAt: string;
  nameOfPerson: string;
  commandUnit: string;
  currentAssignee: string;
  onViewIncident: () => void;
};

function DesktopIncidentWorkItemCard(props: DesktopIncidentWorkItemCardProps) {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        gap: "2rem",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <HeadingAndSubheading
        heading="incidentWorkItem.typeOfIncident"
        subheading={props.typeOfIncident}
      />
      <HeadingAndSubheading
        heading="incidentWorkItem.createdAt"
        subheading={props.createdAt}
      />
      <HeadingAndSubheading
        heading="incidentWorkItem.nameOfPerson"
        subheading={props.nameOfPerson}
      />
      <HeadingAndSubheading
        heading="incidentWorkItem.commandUnit"
        subheading={props.commandUnit}
      />
      <HeadingAndSubheading
        heading="incidentWorkItem.currentAssignee"
        subheading={props.currentAssignee}
      />
      <Button
        variant="contained"
        onClick={props.onViewIncident}
        sx={{ minWidth: "10rem" }}
        data-testid="viewIncident"
      >
        {t("incidentWorkItem.view")}
      </Button>
    </Box>
  );
}

type MobileIncidenWorkItemCardProps = DesktopIncidentWorkItemCardProps & {
  showAssignedOfficer: boolean;
};

function MobileIncidentWorkItemCard(props: MobileIncidenWorkItemCardProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        border: "1px solid",
        borderRadius: "0.5rem",
        borderColor: theme.palette.grey[400],
        padding: "1rem",
        minHeight: "12rem",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ gap: "1rem", flexDirection: "column", display: "flex" }}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
            {props.typeOfIncident}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <HeadingAndSubheading
            heading="incidentWorkItem.createdAt"
            subheading={props.createdAt}
          />
          <Box>
            <HeadingAndSubheading
              heading="incidentWorkItem.nameOfPerson"
              subheading={props.nameOfPerson}
            />

            {props.showAssignedOfficer ? (
              <>
                <HorizontalRule />
                <HeadingAndSubheading
                  heading="incidentWorkItem.currentAssignee"
                  subheading={props.currentAssignee}
                />
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          widht: "100%",
        }}
      >
        <Button
          variant="contained"
          onClick={props.onViewIncident}
          data-testid="viewIncident"
        >
          {t("incidentWorkItem.view")}
        </Button>
      </Box>
    </Box>
  );
}

export function IncidentWorkItemCard(props: IncidentWorkItemCardProps) {
  const theme = useTheme();
  const { t } = useTranslation();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const { incidentWorkItem, participants } = props.incidentWorkItem;
  const owner = participants.find(
    (participant) =>
      participant.role === "owner" || participant.role === "creator",
  );

  const ownerName = owner ? owner.name : t("incidentWorkItem.unknownOwner");

  const { commandUnitName, patientForename, patientSurname } = incidentWorkItem;

  // TODO: get this from the work ite once we've added that column
  const typeOfIncidentForm = "136";

  const created = renderUkDateTime(incidentWorkItem.createdAt, true);

  const derivedIncidentStatus = calculateDerivedIncidentStatus(
    props.incidentWorkItem,
  );

  const typeOfIncident = `${t(`incidentWorkItem.type.${typeOfIncidentForm}`)} - ${t(`incidentWorkItem.${derivedIncidentStatus}`)}`;

  const patientName = `${patientSurname.toUpperCase()}, ${patientForename}`;

  // TODO: logic around accept/reject/cancel transfer buttons

  return isDesktop ? (
    <DesktopIncidentWorkItemCard
      onViewIncident={props.onViewIncident}
      typeOfIncident={typeOfIncident}
      createdAt={created}
      nameOfPerson={patientName}
      commandUnit={commandUnitName}
      currentAssignee={ownerName}
    />
  ) : (
    <MobileIncidentWorkItemCard
      showAssignedOfficer={props.showAssignedOfficerOnMobile}
      onViewIncident={props.onViewIncident}
      typeOfIncident={typeOfIncident}
      createdAt={created}
      nameOfPerson={patientName}
      commandUnit={commandUnitName}
      currentAssignee={ownerName}
    />
  );
}
