import {
  GetIncidentWorkItemResponseSchema,
  IncidentWorkItemWithContext,
} from "@vision/common";
import { defer } from "react-router-dom";
import api from "../../api/index.js";
import { Deferred } from "./util.js";

export type OfficerLandingPageLoaderData = {
  incidentWorkItems: IncidentWorkItemWithContext[];
};

export type OfficerLandingPageLoaderDeferredData =
  Deferred<OfficerLandingPageLoaderData>;

export const officerLandingPageLoader = () => {
  const incidentWorkItems = api.incidentWorkItem
    .getMyActiveIncidentWorkItems()
    .then(async (response) => {
      if (response.status === 200) {
        return GetIncidentWorkItemResponseSchema.parseAsync(response.data).then(
          (data) => data.workItems,
        );
      } else {
        throw Error(`Failed to fetch incident work items: ${response.status}`);
      }
    });

  return defer({
    incidentWorkItems: incidentWorkItems,
  } satisfies OfficerLandingPageLoaderDeferredData);
};
