import { Box, useTheme } from "@mui/material";
import { TranslationKey } from "@vision/common";
import React from "react";
import { TypographyI18N } from "../../TypographyI18N/TypographyI18N.js";

export type EmptyIncidentWorkItemCardProps = {
  title: TranslationKey;
};

export function EmptyIncidentWorkItemCard({
  title,
}: EmptyIncidentWorkItemCardProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        [theme.breakpoints.down("md")]: {
          minHeight: "10rem",
        },
        [theme.breakpoints.up("md")]: {
          minHeight: "3rem",
        },
        background: theme.palette.grey[200],
        borderColor: theme.palette.grey[400],
        borderRadius: "0.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <TypographyI18N
        sx={{
          textTransform: "uppercase",
          maxWidth: "40%",
          lineHeight: "1.2",
          fontWeight: "bold",
        }}
        variant="caption"
        translationKey={title}
      />
    </Box>
  );
}
