import React from "react";

import { CircularProgress, Stack, Typography } from "@mui/material";
import { Await, useRouteLoaderData } from "react-router-dom";
import {
  PlacesOfSafetyLoaderData,
  PlacesOfSafetyLoaderDeferredData,
} from "../../routing/loaders/placesOfSafety.js";

export type PlacesOfSafetyPageProps = Record<string, never>;

export const PlacesOfSafetyPage = (
  _props: PlacesOfSafetyPageProps,
): React.ReactNode => {
  const { placesOfSafety } = useRouteLoaderData(
    "placesOfSafety",
  ) as PlacesOfSafetyLoaderDeferredData;

  return (
    <React.Suspense fallback={<CircularProgress />}>
      <Await
        resolve={placesOfSafety}
        errorElement={<Typography>Something went wrong</Typography>}
      >
        {(data: PlacesOfSafetyLoaderData["placesOfSafety"]) => {
          return (
            <Stack sx={{ display: "flex", textAlign: "center" }}>
              <Typography variant="h3">
                These are the Places of Safety
              </Typography>
              <ul>
                {data.map((placeOfSafety) => (
                  <li key={placeOfSafety.id}>
                    <Typography variant="h3">{placeOfSafety.name}</Typography>
                  </li>
                ))}
              </ul>
            </Stack>
          );
        }}
      </Await>
    </React.Suspense>
  );
};
