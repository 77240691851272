import React from "react";
import { Await, useLoaderData } from "react-router-dom";
import { Error } from "../../pages/Error/Error.js";
import { RootLoaderData } from "../../routing/loaders/root.js";
import { AppDrawer } from "../AppDrawer/AppDrawer.js";
import { Onboarding } from "../Onboarding/Onboarding.js";

export const RootComponent = () => {
  const { user, availableOrganisations } = useLoaderData() as RootLoaderData;

  return (
    <React.Suspense fallback={<Error />}>
      <Await resolve={user} errorElement={<Error />}>
        {(user: RootLoaderData["user"]) => (
          <Await resolve={availableOrganisations} errorElement={<Error />}>
            {(
              availableOrganisations: RootLoaderData["availableOrganisations"],
            ) => {
              return user.isOnboarded === false ? (
                <Onboarding
                  user={user}
                  availableOrganisations={availableOrganisations}
                />
              ) : (
                <AppDrawer user={user} />
              );
            }}
          </Await>
        )}
      </Await>
    </React.Suspense>
  );
};
