import { Box, Container, useTheme } from "@mui/material";
import {
  AvailableOrganisationArray,
  ExtendedVisionUser,
  FormBuilderField,
  UserOrganisationRole,
  availableOrganisationSchema,
  emailSchema,
  nameSchema,
  orgIdSchema,
} from "@vision/common";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  useNavigate,
  useRevalidator,
  useRouteLoaderData,
} from "react-router-dom";
import { z } from "zod";
import api from "../../api/index.js";
import { SinglePageForm } from "../../components/FormBuilder/SinglePageForm/SinglePageForm.js";
import { TypographyI18N } from "../../components/index.js";
import { useToastNotifications } from "../../hooks/useToastNotifications.js";
import { RootLoaderData } from "../../routing/loaders/root.js";

const UserProfileFormDataSchema = z.object({
  name: nameSchema,
  email: emailSchema,
  orgId: orgIdSchema,
  availableOrganisations: availableOrganisationSchema.array(),
  shoulderNumber: z.string(),
  role: z.nativeEnum(UserOrganisationRole),
});

type UserProfileFormData = z.infer<typeof UserProfileFormDataSchema>;

const UserProfileForm = ({
  onSubmit,
  onCancel,
  user,
  availableOrganisations,
}: {
  onSubmit: (values: UserProfileFormData) => void;
  onCancel: () => void;
  user: ExtendedVisionUser;
  availableOrganisations: AvailableOrganisationArray;
}) => {
  const { t } = useTranslation();

  const displayRole = (role: UserOrganisationRole) => {
    switch (role) {
      case UserOrganisationRole.Officer:
        return t("role.officer");
      case UserOrganisationRole.Supervisor:
        return t("role.supervisor");
      case UserOrganisationRole.ClientAdmin:
        return t("role.clientAdmin");
      case UserOrganisationRole.AnalyticsViewer:
        return t("role.analyticsViewer");
      case UserOrganisationRole.GuestUser:
        return t("role.guestUser");
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const content: { fields: FormBuilderField<UserProfileFormData, any>[] } = {
    fields: [
      {
        type: "readonly",
        label: "userProfile.email",
        textFn: (data: UserProfileFormData) => [data.email],
        fieldName: "email",
      },
      {
        type: "textbox",
        fieldName: "name",
        label: "userProfile.name",
      },
      {
        type: "textbox",
        fieldName: "shoulderNumber",
        label: "userProfile.shoulderNumber",
      },
      {
        type: "organisation",
        fieldName: "orgId",
        label: "userProfile.organisationMembership",
        options: (data: UserProfileFormData) => data.availableOrganisations,
      },
      {
        type: "readonly",
        label: "userProfile.role",
        textFn: (data: UserProfileFormData) => [displayRole(data.role)],
        fieldName: "role",
      },
    ],
  };

  return (
    <SinglePageForm<typeof UserProfileFormDataSchema>
      schema={UserProfileFormDataSchema}
      onSubmit={onSubmit}
      content={content}
      initialValues={{
        name: user.name,
        email: user.email,
        shoulderNumber: user.shoulderNumber ?? "",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        orgId: user.sessionContext?.organisationId!,
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
        role: user.sessionContext?.role!,
        availableOrganisations: availableOrganisations,
      }}
      canCancel={true}
      onCancel={onCancel}
      confirmButtonLabel="userProfile.confirm"
    />
  );
};

export const UserProfile = ({
  user,
  availableOrganisations,
  onCancel,
  revalidator,
}: {
  user: ExtendedVisionUser;
  availableOrganisations: AvailableOrganisationArray;
  onCancel: () => void;
  revalidator: { revalidate: () => void };
}) => {
  const theme = useTheme();
  const notifications = useToastNotifications();
  const { t } = useTranslation();

  return (
    <Box>
      <TypographyI18N
        translationKey={"userProfile.title"}
        sx={{
          paddingBottom: theme.spacing(2),
          fontSize: theme.spacing(2.5),
          fontWeight: 700,
        }}
      />
      <TypographyI18N
        translationKey={"userProfile.banner"}
        sx={{
          paddingBottom: theme.spacing(4),
          fontSize: theme.spacing(2),
        }}
      />
      <UserProfileForm
        user={user}
        availableOrganisations={availableOrganisations}
        onSubmit={async (values) => {
          try {
            const response = await api.user.update(user.id, {
              name: values.name,
              organisationId: values.orgId,
              shoulderNumber: values.shoulderNumber,
            });
            if (response.status === 204) {
              notifications.show(t("userProfile.success"), {
                severity: "success",
              });
              revalidator.revalidate();
            } else {
              throw new Error(JSON.stringify(response.data.message));
            }
          } catch {
            notifications.show(t("userProfile.error"), {
              severity: "error",
            });
          }
        }}
        onCancel={onCancel}
      />
    </Box>
  );
};

export const UserProfilePage = () => {
  const { user, availableOrganisations } = useRouteLoaderData(
    "root",
  ) as RootLoaderData;
  const revalidator = useRevalidator();
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Container sx={{ marginTop: theme.spacing(2) }}>
      <UserProfile
        user={user}
        availableOrganisations={availableOrganisations}
        onCancel={() => navigate(-1)}
        revalidator={revalidator}
      />
    </Container>
  );
};
