import {
  CreateIncidentWorkItemRequest,
  FormSection,
  GetIncidentWorkItemResponse,
  IncidentFormId,
  IncidentWorkItemId,
  IncidentWorkItemWithContext,
} from "@vision/common";
import { AxiosInstance } from "axios";
import { makeUseAxios } from "axios-hooks";

export const config = {
  incidentWorkItem: {
    get: (id: IncidentWorkItemId) => ({
      url: `/api/incident-work-item/${id}`,
      method: "get",
    }),
    getMyActiveIncidentWorkItems: () => ({
      url: `/api/incident-work-item`,
      method: "get",
    }),
    create: (
      id: IncidentWorkItemId,
      creatIncidentWorkItemReq: CreateIncidentWorkItemRequest,
    ) => ({
      url: `/api/incident-work-item/${id}`,
      method: "post",
      data: creatIncidentWorkItemReq,
    }),
    updateData(
      id: IncidentWorkItemId,
      formId: IncidentFormId,
      section: FormSection,
      data: Record<string, unknown>,
      validateData: boolean,
    ) {
      return {
        url: `/api/incident-work-item/${id}/form/${formId}/section/${section}`,
        method: "post",
        data,
        params: { validateData },
      };
    },
  },
};

export const api = (apiAxios: AxiosInstance) => {
  const useApiAxios = makeUseAxios({ axios: apiAxios, cache: false });

  return {
    incidentWorkItem: {
      get: (id: IncidentWorkItemId) =>
        apiAxios.request<IncidentWorkItemWithContext | null>(
          config.incidentWorkItem.get(id),
        ),
      getMyActiveIncidentWorkItems: () =>
        apiAxios.request<GetIncidentWorkItemResponse>(
          config.incidentWorkItem.getMyActiveIncidentWorkItems(),
        ),

      create: (
        id: IncidentWorkItemId,
        creatIncidentWorkItemReq: CreateIncidentWorkItemRequest,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.create(id, creatIncidentWorkItemReq),
        ),

      updateData: (
        id: IncidentWorkItemId,
        formId: IncidentFormId,
        section: FormSection,
        data: Record<string, unknown>,
        validateData: boolean,
      ) =>
        apiAxios.request(
          config.incidentWorkItem.updateData(
            id,
            formId,
            section,
            data,
            validateData,
          ),
        ),
    },
    incidentWorkItemHooks: {
      get: function useGetIncidentWorkItem(id: IncidentWorkItemId) {
        return useApiAxios<IncidentWorkItemWithContext | null>(
          config.incidentWorkItem.get(id),
        );
      },
      getMyActiveIncidentWorkItems: function useGetMyActiveIncidentWorkItem() {
        return useApiAxios<GetIncidentWorkItemResponse>(
          config.incidentWorkItem.getMyActiveIncidentWorkItems(),
        );
      },
    },
  };
};
