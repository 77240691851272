import { Box, Dialog, DialogProps, useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import * as React from "react";

const BootstrapDialog = styled(Dialog, {
  shouldForwardProp: (prop) => prop !== "hasPadding",
})<PopupDialogProps>(({ theme, fullScreen, hasPadding, maxWidth }) => ({
  padding: fullScreen ? theme.spacing(1) : 0,
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      ...(!fullScreen && {
        display: "flex",
        padding: hasPadding && theme.spacing(4),
        width: "100%",
        maxWidth: maxWidth ? theme.breakpoints.values[maxWidth] : "536px",
      }),
    },
  },
}));

export interface PopupDialogProps extends DialogProps {
  hasPadding?: boolean;
  testId?: string;
}

const defaultProps: Partial<PopupDialogProps> = {
  hasPadding: true,
  fullScreen: false,
};

export const PopupDialog = (props: PopupDialogProps) => {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down("md"));

  const allProps = { ...defaultProps, ...props };
  const { children, open, onClose, maxWidth, fullScreen, hasPadding } =
    allProps;

  return (
    <BootstrapDialog
      onClose={onClose}
      fullScreen={isMobileView || fullScreen}
      aria-labelledby="popup-dialog"
      open={open}
      maxWidth={maxWidth}
      hasPadding={hasPadding}
    >
      <Box sx={{ m: isMobileView ? 2 : 0 }}>{children}</Box>
    </BootstrapDialog>
  );
};
