import React, { useMemo } from "react";

import { createInitialValues, workflows } from "@vision/common";
import { z } from "zod";
import {
  MultiPageForm,
  MultiPageFormProps,
  MultiPageFormSkeleton,
} from "../../components/FormBuilder/MultiPageForm/MultiPageForm.js";

// TODO: get from config or pass into this
const workflow = workflows["base-0.1"];
const formSchema =
  workflow.triageIncident.formDefinition.sections[0].formSchema;
const strictFieldSchemas =
  workflow.triageIncident.formDefinition.sections[0].strictFieldSchemas;

type TriageIncidentData = z.infer<typeof formSchema>;

///

export function TriageIncidentFormSkeleton() {
  return (
    <MultiPageFormSkeleton<typeof formSchema>
      headerText={"triageIncident.title"}
    />
  );
}

///

export type TriageIncidentFormProps = {
  onSubmit: (value: TriageIncidentData) => void;
  onCancel: () => void;
} & Pick<Partial<MultiPageFormProps<typeof formSchema>>, "initialValues">;

///

export function TriageIncidentForm(props: TriageIncidentFormProps) {
  const initialValuesOrDefaults = useMemo(
    () =>
      props.initialValues ??
      (createInitialValues(strictFieldSchemas) as z.infer<typeof formSchema>),
    [props],
  );

  return (
    <MultiPageForm<typeof formSchema>
      headerText={"triageIncident.title"}
      schema={formSchema}
      initialValues={initialValuesOrDefaults}
      pages={
        // This form only ever has one section
        workflow.triageIncident.formDefinition.sections[0].pages
      }
      onCancel={props.onCancel}
      onSubmit={props.onSubmit}
    />
  );
}
