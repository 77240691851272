import { PlaceOfSafety } from "@thalamos/common";
import { GetPlacesOfSafetyResponseSchema } from "@vision/common";
import api from "../../api/index.js";
import { Deferred } from "./util.js";

export type PlacesOfSafetyLoaderData = {
  placesOfSafety: PlaceOfSafety[];
};

export type PlacesOfSafetyLoaderDeferredData =
  Deferred<PlacesOfSafetyLoaderData>;

export const placesOfSafetyLoader = async () => {
  const placesOfSafety = api.placeOfSafety.getAll().then(async (response) => {
    if (response.status === 200) {
      return GetPlacesOfSafetyResponseSchema.parseAsync(response.data).then(
        (data) => data.placesOfSafety,
      );
    } else {
      throw Error(`Failed to fetch places of safety: ${response.status}`);
    }
  });

  return {
    placesOfSafety: placesOfSafety,
  } satisfies PlacesOfSafetyLoaderDeferredData;
};
