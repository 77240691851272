import {
  Inventory as EndedIncidentsIcon,
  Phone as HelpIcon,
  Home as HomeIcon,
  FolderOpen as InProgressIncidentsIcon,
  Logout as LogoutIcon,
  Add as NewIncidentIcon,
  Notifications as NotificationsIcon,
} from "@mui/icons-material";
import { Avatar, Box, List, Stack, Typography, useTheme } from "@mui/material";
import {
  AppDrawerDivider,
  AppDrawerListItem,
  AppDrawer as ThalamosAppDrawer,
} from "@thalamos/frontend";
import { ExtendedVisionUser } from "@vision/common";
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { config } from "../../config/index.js";
import { createRoute } from "../../routing/createRoute.js";
import { Logo } from "../Logo/Logo.js";
import { TypographyI18N } from "../TypographyI18N/TypographyI18N.js";

export type AppDrawerProps = unknown;

const UserProfileAvatar = ({ user }: { user: ExtendedVisionUser }) => {
  const theme = useTheme();

  return (
    <Avatar
      sx={{
        transform: "scale(0.75)",
        bgcolor: theme.palette.primary.contrastText,
        color: theme.palette.primary.main,
      }}
    >
      {user.name.split(" ").map((n, i) => i < 2 && n[0].toUpperCase())}
    </Avatar>
  );
};

const UserProfileText = ({ user }: { user: ExtendedVisionUser }) => {
  const theme = useTheme();

  return (
    <Box>
      <Typography sx={{ fontSize: theme.spacing(1.75) }}>
        {user.name}
      </Typography>
      {user.shoulderNumber && (
        <Typography sx={{ fontSize: theme.spacing(1.75) }}>
          {user.shoulderNumber}
        </Typography>
      )}
    </Box>
  );
};

const DrawerItems = ({ user }: { user: ExtendedVisionUser }) => {
  const navigate = useNavigate();

  return (
    <Stack sx={{ display: "flex", flexGrow: 1, height: "100%" }}>
      <List>
        <AppDrawerListItem
          icon={<HomeIcon />}
          text={<TypographyI18N translationKey="navigation.home" />}
          onClick={() => navigate(createRoute.home())}
        />
        <AppDrawerListItem
          icon={<NewIncidentIcon />}
          text={<TypographyI18N translationKey="navigation.newIncident" />}
          onClick={() => navigate(createRoute.incidentCreate())}
        />
        <AppDrawerListItem
          icon={<InProgressIncidentsIcon />}
          text={
            <TypographyI18N translationKey="navigation.inProgressIncidents" />
          }
          onClick={() => navigate(createRoute.incidentActive())}
        />
        <AppDrawerListItem
          icon={<EndedIncidentsIcon />}
          text={<TypographyI18N translationKey="navigation.endedIncidents" />}
          onClick={() => navigate(createRoute.incidentArchive())}
        />
        <AppDrawerListItem
          icon={<HelpIcon />}
          text={<TypographyI18N translationKey="navigation.help" />}
          onClick={() => navigate(createRoute.help())}
        />
        <AppDrawerListItem
          icon={<NotificationsIcon />}
          text={<TypographyI18N translationKey="navigation.notifications" />}
          onClick={() => navigate(createRoute.notifications())}
        />
      </List>
      <AppDrawerDivider />
      <Stack sx={{ marginTop: "auto" }}>
        <List>
          <AppDrawerListItem
            icon={<UserProfileAvatar user={user} />}
            text={<UserProfileText user={user} />}
            onClick={() => navigate(createRoute.userProfile(user.id))}
          />
        </List>
        <AppDrawerDivider />
        <List>
          <AppDrawerListItem
            icon={<LogoutIcon />}
            text={<TypographyI18N translationKey="navigation.logout" />}
            onClick={() => {
              window.location.href = `${config.backendUrl}/api/oauth/logout`; // TODO: Get from config.backendUrl,
            }}
          />
        </List>
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            justifyContent: "center",
            padding: "0.5rem",
          }}
        >
          <Typography>©️ 2024 Thalamos</Typography>
        </Box>
      </Stack>
    </Stack>
  );
};

export const AppDrawer = ({
  user,
}: {
  user: ExtendedVisionUser;
}): React.ReactNode => {
  return (
    <Stack>
      <ThalamosAppDrawer
        logo={<Logo />}
        drawerItems={<DrawerItems user={user} />}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Outlet />
        </Box>
      </ThalamosAppDrawer>
    </Stack>
  );
};
