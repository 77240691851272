import { Box, Typography } from "@mui/material";
import React from "react";

export const Logo = () => (
  <Box
    sx={{
      borderColor: "white",
      borderWidth: 2,
      borderStyle: "solid",
      padding: "0.5rem",
      borderRadius: "0.5rem",
      textAlign: "center",
    }}
  >
    <Typography>Your Logo Here</Typography>
  </Box>
);
