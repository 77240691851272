import { Done, RadioButtonUncheckedOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import {
  IncidentFormType,
  IncidentType,
  IncidentWorkItemWithContext,
  TranslationKey,
  workflows,
} from "@vision/common";
import React from "react";
import { useTranslation } from "react-i18next";
import { Await, useNavigate, useRouteLoaderData } from "react-router-dom";
import IncidentBanner from "../../components/IncidentBanner/IncidentBanner.js";
import { TypographyI18N } from "../../components/index.js";
import { createRoute } from "../../routing/createRoute.js";
import { IncidentWorkItemLoaderDeferredData } from "../../routing/loaders/incidentWorkItem.js";
import { Error } from "../Error/Error.js";

type StepData = {
  label: string;
  completed: boolean;
  onClick?: () => void;
};

type FormProgressProps = {
  steps: StepData[];
  placeOfSafety: string;
  arrivalButtonDisabled?: boolean;
  handoverButtonDisabled?: boolean;
};

const FormProgressSteps = ({ steps }: { steps: StepData[] }) => {
  const theme = useTheme();

  return (
    <Box sx={{ mx: { xs: 0, sm: 10 }, pt: 2 }}>
      <Stepper orientation="vertical" nonLinear>
        {steps.map((step, index) => (
          <Step key={index} active={true} completed={step.completed}>
            <StepLabel
              icon={
                step.completed ? (
                  <Done color="success" />
                ) : (
                  <RadioButtonUncheckedOutlined color="action" />
                )
              }
              StepIconProps={{
                sx: {
                  color: step.completed
                    ? "success.main"
                    : theme.palette.common.black,
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <TypographyI18N
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "0.8125rem", sm: "1rem" },
                    color: theme.palette.common.black,
                  }}
                  translationKey={step.label}
                />

                <Typography
                  sx={{
                    color: theme.palette.primary.main,
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontWeight: 500,
                    fontSize: "0.8125rem",
                  }}
                  data-testid={`action-button-${index}`}
                  onClick={step.onClick}
                >
                  {step.completed ? (
                    <TypographyI18N
                      fontSize={"0.8125rem"}
                      fontWeight={500}
                      translationKey={"formProgressPage.view"}
                      data-testid={`view-action-${index}`}
                    />
                  ) : (
                    <TypographyI18N
                      fontSize={"0.8125rem"}
                      fontWeight={500}
                      translationKey="formProgressPage.add"
                      data-testid={`add-action-${index}`}
                    />
                  )}
                </Typography>
              </Box>
              <Divider />
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export const extractIncidentDetails = (
  incidentWorkItem: IncidentWorkItemWithContext | undefined,
  unknownTranslation: TranslationKey,
) => {
  if (incidentWorkItem === undefined) {
    return { fullName: unknownTranslation, incidentStatus: unknownTranslation };
  }
  const patientForename = incidentWorkItem.incidentWorkItem.patientForename;
  const patientSurname = incidentWorkItem.incidentWorkItem.patientSurname;
  const incidentStatus = incidentWorkItem?.incidentWorkItem.status;

  return { fullName: `${patientForename} ${patientSurname}`, incidentStatus };
};

export function FormProgressWrapper() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { incidentWorkItem } = useRouteLoaderData(
    "incidentWorkItem",
  ) as IncidentWorkItemLoaderDeferredData;

  const render = (
    incidentWorkItem: IncidentWorkItemWithContext | undefined,
  ) => {
    const { fullName, incidentStatus } = extractIncidentDetails(
      incidentWorkItem,
      t("unknown"),
    );

    const currentWorkFlowVersion =
      incidentWorkItem?.incidentWorkItem.workflowVersion;

    const currentWorkItemForm = (incidentWorkItem?.forms ?? []).find(
      (form) =>
        form.incidentWorkItemId === incidentWorkItem?.incidentWorkItem.id &&
        form.formDefinitionVersion === currentWorkFlowVersion &&
        form.status === "active",
    );

    if (currentWorkItemForm === undefined) {
      return <Error />;
    }

    const loadWorkFlow = workflows[currentWorkItemForm.formDefinitionVersion];

    // TODO - we need to dynamically load the current workflow based on what flow we have chosen from the triage form
    const loadCurrentWorkFlow =
      loadWorkFlow?.[IncidentType.section136]?.formDefinition ?? {};
    const triageWorkFlow =
      loadWorkFlow?.[IncidentFormType.triage]?.formDefinition ?? {};
    const createIncidentWorkFlow =
      loadWorkFlow?.[IncidentFormType.createIncident]?.formDefinition ?? {};

    // Merge createIncidentWorkFlow and triageWorkFlow sections with a `+` between their names
    const mergedCreateAndTriageSections = (() => {
      const triageSections = triageWorkFlow?.sections ?? [];
      const createSections = createIncidentWorkFlow?.sections ?? [];
      const triageComplete =
        (incidentWorkItem?.forms.find(
          (form) => form.formType === "triageIncident",
        )?.sections?.[0]?.data ?? null) !== null;

      // Combine sections by index, with a `+` for overlapping sections
      const combinedSections = triageSections.map((section, index) => {
        const createSection = createSections[index];
        // if theres a triage section and create section, combine their names using a translation key
        if (createSection && section) {
          return {
            name: "incidentFormSections.incidentAndTriage",
            completed: triageComplete,
          };
        }
        return {
          name: section.name,
          completed: triageComplete,
        };
      });

      return combinedSections;
    })();

    // Combine all workflows into steps
    const currentSteps = [
      ...mergedCreateAndTriageSections.map(
        (section) =>
          ({
            label: section.name,
            completed: section.completed,
            onClick: () => {
              navigate(
                incidentWorkItem
                  ? createRoute.incidentTriagePage(
                      incidentWorkItem.incidentWorkItem.id,
                    )
                  : createRoute.home(),
              );
            },
          }) satisfies StepData,
      ),
      ...(loadCurrentWorkFlow?.sections.map(
        (section) =>
          ({
            label: section.name,
            completed: false,
          }) satisfies StepData,
      ) ?? []),
    ];

    return (
      <Stack
        data-testid="form-progress"
        sx={{
          [theme.breakpoints.up("md")]: {
            padding: "5rem",
          },
          [theme.breakpoints.down("md")]: {
            padding: "0.5rem",
          },
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
        }}
      >
        <Paper
          sx={{
            padding: { xs: 0, sm: 3 },
            mx: "auto",
            boxShadow: { xs: 0, sm: 3 },
            maxWidth: { xs: "100%", sm: "80%" },
            width: "100%",
          }}
        >
          {/* Incident Banner */}
          <IncidentBanner name={fullName} incidentStatus={incidentStatus} />
          {/* Form Progress */}
          <FormProgress
            steps={currentSteps}
            // TODO - currently hardcoded, need to get this from the incidentWorkItem or some api
            placeOfSafety="Not known"
            arrivalButtonDisabled={false}
            handoverButtonDisabled={false}
          />
        </Paper>
      </Stack>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await resolve={incidentWorkItem} errorElement={<Error />}>
        {(resolvedData: IncidentWorkItemWithContext) => render(resolvedData)}
      </Await>
    </React.Suspense>
  );
}

export function FormProgress({
  steps,
  placeOfSafety,
  arrivalButtonDisabled = true,
  handoverButtonDisabled = true,
}: FormProgressProps) {
  const theme = useTheme();
  return (
    <>
      {/* Form Progress Steps */}
      <Box sx={{ px: { xs: 1, sm: 2 } }}>
        <FormProgressSteps steps={steps} />
      </Box>

      {/* Place of Safety Section */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Grid
          container
          sx={{
            backgroundColor: "common.background",
            maxWidth: { xs: "100%" },
            p: 1.5,
            mx: { xs: 0, sm: 5 },
          }}
        >
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
              <Grid container>
                <Grid item xs={9}>
                  <Box sx={{ display: "flex", alignItems: "baseline" }}>
                    <TypographyI18N
                      sx={{ fontSize: "0.75rem" }}
                      color="text.secondary"
                      translationKey="formProgressPage.activePlaceOfSafety"
                    />
                    <Typography
                      sx={{
                        marginLeft: "0.25rem",
                        fontWeight: "bold",
                        fontSize: { xs: "0.8125rem", sm: "1rem" },
                      }}
                    >
                      {placeOfSafety}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <TypographyI18N
                    sx={{
                      cursor: "pointer",
                      textDecoration: "underline",
                      fontWeight: 500,
                      fontSize: "0.8125rem",
                      color: theme.palette.primary.main,
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                    translationKey="formProgressPage.add"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Arrival and Handover Buttons Section */}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 3 }}>
        <Grid
          container
          sx={{
            maxWidth: { xs: "100%" },
            mx: { xs: 0, sm: 5 },
          }}
        >
          <Grid container justifyContent="space-around">
            {/* Arrival Section */}
            <Grid item xs={5}>
              <Stack direction="column" spacing={1}>
                <TypographyI18N
                  sx={{
                    mb: 1,
                    fontSize: { xs: "0.8125", sm: "1rem" },
                    fontWeight: 600,
                  }}
                  translationKey="formProgressPage.arrival"
                />
                <TypographyI18N
                  variant="caption"
                  translationKey="formProgressPage.confirmArrival"
                />
                <Button
                  variant="contained"
                  data-testid="arrival-button"
                  disabled={arrivalButtonDisabled}
                  sx={{ mt: 2, maxWidth: 300, width: "100%" }}
                >
                  <TypographyI18N
                    sx={{ fontWeight: 500, fontSize: "0.8125rem" }}
                    translationKey="formProgressPage.arrival"
                  />
                </Button>
              </Stack>
            </Grid>

            <Grid item sx={{ display: "flex", alignItems: "center" }}>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  minHeight: { xs: "100%", sm: "300px" },
                  height: "100%",
                  borderColor: "divider",
                }}
              />
            </Grid>

            {/* Handover Section */}
            <Grid item xs={5}>
              <Stack direction="column" spacing={1}>
                <TypographyI18N
                  translationKey="formProgressPage.handoverAndShare"
                  sx={{
                    mb: 1,
                    fontSize: { xs: "0.8125", sm: "1rem" },
                    fontWeight: 600,
                  }}
                />
                <TypographyI18N
                  variant="caption"
                  translationKey="formProgressPage.confirmHandover"
                />
                <Button
                  variant="contained"
                  data-testid="handover-button"
                  disabled={handoverButtonDisabled}
                  sx={{ mt: 2, maxWidth: 300, width: "100%" }}
                >
                  <TypographyI18N
                    sx={{ fontWeight: 500, fontSize: "0.8125rem" }}
                    translationKey="formProgressPage.handover"
                  />
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {/* End and Transfer Incident Buttons */}
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          zIndex: 1000,
          backgroundColor: "common.background",
          borderRadius: "9px",
        }}
      >
        <Grid
          container
          sx={{
            mt: 3,
            p: 2,
            flexDirection: "row",
            gap: { xs: 2, sm: 0 },
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid
            item
            xs={6}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Button
              variant="outlined"
              color="error"
              sx={{
                width: "100%",
                fontSize: "0.75rem",
                backgroundColor: "white",
              }}
            >
              <TypographyI18N
                variant="caption"
                sx={{ fontWeight: 600, fontSize: "0.75rem" }}
                translationKey="formProgressPage.endIncident"
              />
            </Button>
          </Grid>

          <Grid
            item
            xs={4}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              sx={{
                width: "100%",
                fontSize: "0.75rem",
                backgroundColor: "white",
              }}
            >
              <TypographyI18N
                variant="caption"
                sx={{ fontWeight: 600, fontSize: "0.75rem" }}
                translationKey="formProgressPage.transfer"
              />
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
