import { Divider, Stack, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  IncidentFormType,
  StartNewIncidentWorkItem,
} from "../../components/IncidentWorkItems/StartNewIncidentWorkItem/StartNewIncidentWorkItem.js";
import { createRoute } from "../../routing/createRoute.js";
import { HomePageIncidents } from "./HomePageIncidents.js";

const HomePage = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  // TODO: get these from org config on user
  const enabledForms: IncidentFormType[] = [
    "section136",
    "section56",
    "voluntary",
  ];

  return (
    <Stack
      data-testid="home"
      sx={{
        [theme.breakpoints.up("md")]: {
          padding: "5rem",
        },
        [theme.breakpoints.down("md")]: {
          padding: "2rem",
        },
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <StartNewIncidentWorkItem
        enabledForms={enabledForms}
        onNewForm={() => navigate(createRoute.incidentCreate())}
      />

      <Divider />

      <HomePageIncidents />
    </Stack>
  );
};

export default HomePage;
