import React from "react";

import { ArrowBack } from "@mui/icons-material";
import { Box, IconButton, LinearProgress, Stack } from "@mui/material";
import { TranslationKey } from "@vision/common";
import { TypographyI18N } from "../../index.js";

///

export type HeaderProps = {
  title: TranslationKey;
  page: number;
  totalPages: number;
  onBack: () => void;
};

export const Header = (props: HeaderProps) => (
  <HeaderInner type={"loaded"} {...props} />
);

///

export type HeaderSkeletonProps = Pick<HeaderProps, "title">;

export const HeaderSkeleton = ({ title }: HeaderSkeletonProps) => (
  <HeaderInner type={"loading"} title={title} />
);

///

type HeaderInnerProps =
  | ({ type: "loading" } & HeaderSkeletonProps)
  | ({ type: "loaded" } & HeaderProps);

export const HeaderInner = (props: HeaderInnerProps) => {
  return (
    <Stack>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <IconButton
          disabled={props.type === "loading"}
          onClick={props.type === "loading" ? undefined : props.onBack}
        >
          <ArrowBack />
        </IconButton>
        <TypographyI18N translationKey={props.title} />
      </Box>
      <LinearProgress
        variant="determinate"
        value={
          props.type === "loading"
            ? 0
            : (100 * (props.page + 1)) / props.totalPages
        }
      />
    </Stack>
  );
};
