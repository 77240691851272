import React from "react";

import { Box, Button, useTheme } from "@mui/material";
import { TypographyI18N } from "../../components/TypographyI18N/TypographyI18N.js";
import { config } from "../../config/index.js";

const loginRedirectUrl = () => {
  return `${config.backendUrl}/api/oauth/login`; // TODO: Get from config.backendUrl,
};

export const Login = ({ logo }: { logo: React.ReactNode }): React.ReactNode => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: theme.spacing(62.5),
          padding: theme.spacing(4),
          display: "flex",
          flexDirection: "column",
          backgroundColor: theme.palette.secondary.main,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {logo}
        <TypographyI18N
          translationKey="login.loginToAccess"
          sx={{
            textAlign: "center",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            fontSize: theme.spacing(2),
          }}
        />
        <Button
          sx={{
            backgroundColor: theme.palette.primary.main,
            "&:hover": {
              backgroundColor: theme.palette.primary.main,
            },
            width: theme.spacing(37.5),
            height: theme.spacing(6),
          }}
          onClick={() => {
            window.location.href = loginRedirectUrl();
          }}
        >
          <TypographyI18N
            translationKey="login.login"
            sx={{
              color: theme.palette.primary.contrastText,
              fontWeight: theme.typography.fontWeightBold,
              fontSize: theme.spacing(1.75),
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};
