import React, { useCallback, useEffect, useState } from "react";

import { Box, Skeleton, Stack, useTheme } from "@mui/material";
import { FormBuilderField, TranslationKey } from "@vision/common";
import { Formik, useFormikContext } from "formik";
import { isEqual } from "lodash-es";
import { z } from "zod";
import { toFormikValidate, toFormikValidationSchema } from "zod-formik-adapter";
import { Field } from "../Field.js";
import { Footer, FooterSkeleton } from "./Footer.js";
import { Header, HeaderSkeleton } from "./Header.js";

///

/**
 * Watches the values of fields changing and resets fields to their initial
 * values if they are not visible.
 */
function FieldValueNormalizer<
  Schema extends z.ZodTypeAny,
  Form extends Record<string, unknown>,
>({
  fields,
  initialValues,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fields: Array<FormBuilderField<Form, any>>;
  initialValues: z.infer<Schema>;
}) {
  const { values, setValues } = useFormikContext<z.infer<Schema>>();

  useEffect(() => {
    // Figure out which fields are not visible based on the latest values
    const hiddenFields = fields.filter(
      (field) => !(field?.isVisible?.({ formValues: values }) ?? true),
    );

    // For any fields which are not visible, set their initial value
    const newValues = {
      ...values,
      ...Object.fromEntries(
        hiddenFields.map((field) => [
          field.fieldName,
          initialValues[field.fieldName],
        ]),
      ),
    };

    if (isEqual(values, newValues) === false) {
      setValues(newValues);
    }
  }, [fields, initialValues, setValues, values]);

  return null;
}

export type MultiPageFormProps<Schema extends z.ZodTypeAny> = {
  headerText: TranslationKey;
  schema: Schema;
  initialValues: z.infer<Schema>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pages: { fields: FormBuilderField<z.infer<Schema>, any>[] }[];
  onCancel: () => void;
  onSubmit: (value: z.infer<Schema>) => void;
};

export function MultiPageForm<Schema extends z.ZodTypeAny>(
  props: MultiPageFormProps<Schema>,
) {
  return <MultiPageFormInner<Schema> type={"loaded"} {...props} />;
}

///

export type MultiPageFormSkeletonProps<Schema extends z.ZodTypeAny> = Pick<
  MultiPageFormProps<Schema>,
  "headerText"
>;

export function MultiPageFormSkeleton<Schema extends z.ZodTypeAny>({
  headerText,
}: MultiPageFormSkeletonProps<Schema>) {
  return (
    <MultiPageFormInner<Schema> type={"loading"} headerText={headerText} />
  );
}

///

export type MultiPageFormInnerProps<Schema extends z.ZodTypeAny> =
  | ({ type: "loading" } & MultiPageFormSkeletonProps<Schema>)
  | ({
      type: "loaded";
    } & MultiPageFormProps<Schema>);

export function MultiPageFormInner<Schema extends z.ZodTypeAny>(
  props: MultiPageFormInnerProps<Schema>,
) {
  const theme = useTheme();

  // Track which page we are on
  const [pageIndex, setPageIndex] = useState(0);
  const [visitedPageIndexes, setVisitedPageIndexes] = useState<number[]>([]);

  const onCleanupAndSubmit = useCallback(
    (values: z.infer<Schema>) => {
      if (props.type === "loaded") {
        props.onSubmit(props.schema.parse(values));
      }
    },
    [props],
  );

  const onBack = useCallback(() => {
    if (props.type === "loaded") {
      if (visitedPageIndexes.length === 0) {
        props.onCancel();
      } else {
        // Pop the last value off the stack
        // Don't use Array.pop() as we don't want to mutate the React state directly
        const targetPageIndex =
          visitedPageIndexes[visitedPageIndexes.length - 1];
        setVisitedPageIndexes(
          visitedPageIndexes.slice(0, visitedPageIndexes.length - 1),
        );
        setPageIndex(targetPageIndex);
      }
    }
  }, [props, visitedPageIndexes]);

  return (
    <Stack
      sx={{
        maxWidth: "50rem",
        padding: "1rem",
        [theme.breakpoints.down("md")]: { minWidth: "100%" },
        [theme.breakpoints.up("md")]: { minWidth: "30rem" },
      }}
    >
      {props.type === "loading" ? (
        <HeaderSkeleton title={props.headerText} />
      ) : (
        <Header
          title={props.headerText}
          page={pageIndex}
          totalPages={props.pages.length}
          onBack={onBack}
        />
      )}
      {props.type === "loading" ? (
        <Stack gap={"0.25rem"} sx={{ marginTop: "2rem" }}>
          <Skeleton variant="text" width={"50%"} />
          <Skeleton variant="rectangular" height={55} />
          <Box sx={{ height: "2rem" }} />
          <FooterSkeleton />
        </Stack>
      ) : (
        <Formik<z.infer<Schema>>
          validationSchema={toFormikValidationSchema(props.schema)}
          validate={toFormikValidate(props.schema)}
          validateOnChange={true}
          initialValues={props.initialValues}
          onSubmit={onCleanupAndSubmit}
        >
          {(formikProps) => {
            const values = formikProps.values;
            const allFields = props.pages.flatMap((page) => page.fields);

            // What fields are visible based on the branching
            // rules and current values in the form?
            const visibleFieldsOnForm = allFields.filter(
              (field) =>
                // Assume all of the fields are visible unless the visibility
                // function tells us otherwise.
                field.isVisible?.({ formValues: values }) ?? true,
            );

            // What pages are visible based on the visible fields?
            const visiblePages = props.pages.map(
              (page) =>
                page.fields.filter((field) =>
                  visibleFieldsOnForm
                    .map((field) => field.fieldName)
                    .includes(field.fieldName),
                ).length > 0,
            );

            const currentPage = props.pages[pageIndex];
            const fieldsOnPage = currentPage.fields;
            const visibleFieldsOnPage = fieldsOnPage.filter((field) =>
              visibleFieldsOnForm
                .map((field) => field.fieldName)
                .includes(field.fieldName),
            );
            return (
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  marginTop: "2rem",
                  justifyContent: "space-between",
                  minHeight: "100%",
                }}
              >
                <FieldValueNormalizer<z.infer<Schema>, z.infer<Schema>>
                  fields={allFields}
                  initialValues={props.initialValues}
                />
                {visibleFieldsOnPage.map((field) => (
                  <Field key={field.fieldName} field={field} {...formikProps} />
                ))}
                <Footer
                  onCancel={props.onCancel}
                  onNext={async () => {
                    // Perform validation on all fields.
                    const fieldErrors = await formikProps.validateForm();

                    // See if any fields on this page are invalid.
                    const pageIsValid =
                      Object.keys(fieldErrors).filter((field) =>
                        visibleFieldsOnPage
                          .map((field) => field.fieldName)
                          .includes(field),
                      ).length === 0;

                    if (pageIsValid) {
                      // Advance to the nearest page with visible fields
                      let nextPageIndex: number | null = null;
                      for (
                        let considerPageIndex = pageIndex + 1;
                        considerPageIndex < props.pages.length;
                        considerPageIndex++
                      ) {
                        if (visiblePages[considerPageIndex] === true) {
                          nextPageIndex = considerPageIndex;
                          break;
                        }
                      }

                      if (nextPageIndex === null) {
                        onCleanupAndSubmit(values);
                      } else {
                        // Record that we have visited this page
                        setVisitedPageIndexes([
                          ...visitedPageIndexes,
                          pageIndex,
                        ]);

                        // Move to the next page
                        setPageIndex(nextPageIndex);
                      }
                    } else {
                      // Touch all fields on the page so that errors are shown
                      formikProps.setTouched(
                        fieldsOnPage.reduce(
                          (acc, field) => ({
                            ...acc,
                            [field.fieldName]: true,
                          }),
                          {},
                        ),
                      );
                    }
                  }}
                />
              </Stack>
            );
          }}
        </Formik>
      )}
    </Stack>
  );
}
