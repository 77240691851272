import { Divider } from "@mui/material";
import { IncidentWorkItemWithContext } from "@vision/common";
import React from "react";
import { Await, useLoaderData } from "react-router-dom";
import {
  OfficerLandingPageLoaderData,
  OfficerLandingPageLoaderDeferredData,
} from "../../routing/loaders/officerLandingPage.js";
import { Error } from "../Error/Error.js";
import { IncidentList } from "./IncidentList.js";

// TODO: filter these properly once transfer logic is worked through
const isMyIncident = (_incident: IncidentWorkItemWithContext) => {
  return true;
};

export const HomePageIncidents = () => {
  const { incidentWorkItems } =
    useLoaderData() as OfficerLandingPageLoaderDeferredData;

  const render = (
    incidentWorkItems: IncidentWorkItemWithContext[] | undefined,
  ) => {
    const myIncidents = (incidentWorkItems ?? []).filter(isMyIncident);

    const transferredIncidents = (incidentWorkItems ?? []).filter(
      (incident) => !isMyIncident(incident),
    );

    return (
      <>
        <IncidentList
          incidentWorkItems={myIncidents}
          mode="myIncidents"
          isLoading={incidentWorkItems === undefined}
        />
        <Divider />
        <IncidentList
          incidentWorkItems={transferredIncidents}
          mode="transferredIncidents"
          isLoading={incidentWorkItems === undefined}
        />
      </>
    );
  };

  return (
    <React.Suspense fallback={render(undefined)}>
      <Await resolve={incidentWorkItems} errorElement={<Error />}>
        {(s: OfficerLandingPageLoaderData["incidentWorkItems"]) => render(s)}
      </Await>
    </React.Suspense>
  );
};
