import React from "react";

import {
  AvailableOrganisationArray,
  OrganisationId,
  workflows,
} from "@vision/common";
import { z } from "zod";
import { MultiPageForm } from "../../components/FormBuilder/MultiPageForm/MultiPageForm.js";

// TODO: get from config or pass into this
const workflow = workflows["base-0.1"];

const schema = workflow.createIncident.formDefinition.sections[0].formSchema;

type CreateIncidentWorkItemData = z.infer<typeof schema>;

export type CreateIncidentWorkItemFormProps = {
  onSubmit: (value: CreateIncidentWorkItemData) => void;
  onCancel: () => void;
  defaultCommandUnitOrganisationId: OrganisationId;
  availableOrganisations: AvailableOrganisationArray;
};

export function CreateIncidentWorkItemForm({
  onSubmit,
  onCancel,
  defaultCommandUnitOrganisationId,
  availableOrganisations,
}: CreateIncidentWorkItemFormProps) {
  return (
    <MultiPageForm<typeof schema>
      headerText={"createIncident.newIncidentForm"}
      schema={schema}
      initialValues={{
        dispatchNumber: "",
        dispatchNumberCreatedAt: "",
        personForename: "",
        personSurname: "",
        commandUnit: defaultCommandUnitOrganisationId,
        availableOrganisations: availableOrganisations,
      }}
      // This form only ever has one section
      pages={workflow.createIncident.formDefinition.sections[0].pages}
      onCancel={onCancel}
      onSubmit={onSubmit}
    />
  );
}
