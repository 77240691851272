import { IncidentWorkItemIdUnbranded, UserIdUnbranded } from "@vision/common";

export const createRoute = {
  help: () => "/help",
  home: () => "/",
  incidentActive: () => "/incident/active",
  incidentArchive: () => "/incident/archive",
  incidentCreate: () => "/incident/create",
  incidentProgressPage: (incidentId: IncidentWorkItemIdUnbranded) =>
    `/incident/${incidentId}/progress`,
  incidentTriagePage: (incidentId: IncidentWorkItemIdUnbranded) =>
    `/incident/${incidentId}/triage`,
  notifications: () => "/notifications",
  login: () => "/login",
  userProfile: (id: UserIdUnbranded) => `/profile/${id}`,
};
