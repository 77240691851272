import {
  AccessTime as AccessTimeIcon,
  ShareOutlined as ShareOutlinedIcon,
} from "@mui/icons-material";
import { Box, Typography, useTheme } from "@mui/material";
import { IncidentWorkItemUnbranded, TranslationKey } from "@vision/common";
import React from "react";
import { TypographyI18N } from "../TypographyI18N/TypographyI18N.js";

interface IncidentBannerProps {
  name: string;
  incidentStatus: IncidentWorkItemUnbranded["status"];
  isViewShareFormDisabled?: boolean;
  isViewTimelineDisabled?: boolean;
}

interface ClickableTypographyButtonProps {
  isDisabled: boolean;
  onClick: () => void;
  icon: React.ReactNode;
  translationKey: TranslationKey;
}

const ClickableTypographyButton = ({
  isDisabled,
  onClick,
  icon,
  translationKey,
}: ClickableTypographyButtonProps) => {
  const theme = useTheme();
  return (
    <Box
      onClick={!isDisabled ? onClick : undefined}
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {icon}
      <TypographyI18N
        sx={{
          color: isDisabled
            ? theme.palette.text.disabled
            : theme.palette.text.deepNavy,
          display: "flex",
          fontSize: "0.8125rem",
          alignItems: "center",
          cursor: isDisabled ? "not-allowed" : "pointer",
          fontWeight: 600,
          textDecoration: "underline",
        }}
        translationKey={translationKey}
      />
    </Box>
  );
};

export default function IncidentBanner({
  name,
  incidentStatus,
  isViewShareFormDisabled = false,
  isViewTimelineDisabled = false,
}: IncidentBannerProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        padding: { xs: theme.spacing(1, 2), sm: theme.spacing(3) },
        backgroundColor: "banner.background",
        borderRadius: 3,
        flexDirection: { xs: "column", sm: "row" },
        alignItems: { xs: "flex-start", sm: "center" },
      }}
    >
      <Box
        sx={{
          display: { xs: "grid", sm: "flex" },
          gridTemplateColumns: { xs: "1fr 1fr" },
          gap: { xs: 0, sm: 5 },
          alignItems: { xs: "center", sm: "flex-end" },
          width: "100%",
        }}
      >
        {/* Name Section */}
        <Box>
          <TypographyI18N
            sx={{ fontSize: { xs: "0.6875rem", sm: "0.875rem" } }}
            color="text.secondary"
            translationKey="incidentBanner.personName"
          />
          <Typography
            sx={{ fontSize: "0.8125rem", fontWeight: 600 }}
            color="text.primary"
          >
            {name}
          </Typography>
        </Box>

        {/* Incident Status Section */}
        <Box>
          <TypographyI18N
            sx={{ fontSize: { xs: "0.6875rem", sm: "0.875rem" } }}
            color="text.secondary"
            translationKey="incidentBanner.incidentStatus"
          />
          <Typography
            sx={{ fontSize: "0.8125rem", fontWeight: 600 }}
            color="text.primary"
          >
            {incidentStatus}
          </Typography>
        </Box>

        {/* View Shared Code Link */}
        <Box sx={{ mt: { xs: 1, sm: 0 } }}>
          <ClickableTypographyButton
            isDisabled={isViewShareFormDisabled}
            // TODO - Implement the view shared code dialog
            onClick={() => alert("View Shared Code clicked")}
            icon={<ShareOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />}
            translationKey="incidentBanner.viewShareForm"
          />
        </Box>

        {/* View Timeline Link */}
        <Box sx={{ mt: { xs: 1, sm: 0 } }}>
          <ClickableTypographyButton
            isDisabled={isViewTimelineDisabled}
            // TODO - Implement the view timeline functionality
            onClick={() => alert("View Timeline clicked")}
            icon={<AccessTimeIcon fontSize="small" sx={{ mr: 0.5 }} />}
            translationKey="incidentBanner.viewTimeline"
          />
        </Box>
      </Box>
    </Box>
  );
}
