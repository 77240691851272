import {
  AvailableOrganisationArray,
  AvailableOrganisationArraySchema,
  ExtendedVisionUser,
  ExtendedVisionUserSchema,
  OrganisationId,
} from "@vision/common";
import { redirect } from "react-router-dom";
import api from "../../api/index.js";
import i18n from "../../i18n.js";
import { setUser } from "../../tracing.js";

export type RootLoaderData = {
  user: ExtendedVisionUser;
  availableOrganisations: AvailableOrganisationArray;
};

const getAvailableOrganisations = async (
  orgId: OrganisationId,
): Promise<AvailableOrganisationArray> => {
  const response = await api.organisation.getAvailableOrganisations(orgId);

  if (response.status === 200) {
    const availableOrganisations =
      await AvailableOrganisationArraySchema.parseAsync(response.data);
    const sortedOrganisations = availableOrganisations.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

    return sortedOrganisations;
  }

  throw new Error(`Failed to fetch child organisations: ${response.status}`);
};

export const rootLoader = async (): Promise<RootLoaderData | Response> => {
  try {
    const response = await api.user.me();
    if (response.status === 200) {
      const user = await ExtendedVisionUserSchema.parseAsync(response.data);

      if (user.sessionOrganisationConfiguration?.language) {
        i18n.changeLanguage(user.sessionOrganisationConfiguration.language);
      }

      const orgId = user.sessionContext?.organisationId;
      if (!orgId) {
        throw new Error("User has no organisation");
      }

      const availableOrganisations = await getAvailableOrganisations(orgId);

      // Set datadog tracing user
      setUser(user);

      return { user, availableOrganisations };
    }
  } catch (_error) {
    // ignore error
  }
  throw redirect("/login");
};
