import { Box } from "@mui/material";
import { TranslationKey } from "@vision/common";
import React from "react";
import { TypographyI18N } from "../../TypographyI18N/TypographyI18N.js";

export const FormError = ({ error, ...props }: { error: TranslationKey }) => {
  return (
    <Box sx={{ ml: 1.5, pt: 0.5 }}>
      <TypographyI18N
        translationKey={error}
        color="error"
        fontWeight={"400"}
        fontSize={"0.75rem"}
        {...props}
      />
    </Box>
  );
};
