import { Stack, Typography } from "@mui/material";
import React from "react";

export type ErrorProps = unknown;

export const Error = (): React.ReactNode => {
  return (
    <Stack data-testid="error" sx={{ display: "flex", textAlign: "center" }}>
      <Typography variant="h3">Something went wrong</Typography>
    </Stack>
  );
};
