import {
  ExtendedVisionUser,
  UserId,
  UserUpdateRequestUnbranded,
} from "@vision/common";
import { AxiosInstance } from "axios";

export const config = {
  user: {
    me: () => ({
      url: `/api/user/me`,
      method: "get",
    }),
    update: (id: UserId, updateUserRequest: UserUpdateRequestUnbranded) => ({
      url: `/api/user/${id}/update`,
      method: "post",
      data: updateUserRequest,
    }),
  },
};

export const api = (apiAxios: AxiosInstance) => {
  return {
    user: {
      me: () => apiAxios.request<ExtendedVisionUser>(config.user.me()),
      update: (id: UserId, updateUserRequest: UserUpdateRequestUnbranded) =>
        apiAxios.request(config.user.update(id, updateUserRequest)),
    },
  };
};
