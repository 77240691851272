import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";

dayjs.extend(utc);
dayjs.extend(timezone);

export function renderUkDateTime(date: Date, includeTime = false) {
  return dayjs
    .utc(date)
    .tz("Europe/London")
    .format(includeTime ? "DD MMM YYYY - HH:mm" : "DD MMM YYYY");
}
