import { btp, common, met } from "@vision/common";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

export const resources = {
  en: {
    translation: common,
  },
  enMet: {
    translation: met,
  },
  enBtp: {
    translation: btp,
  },
};

const config = {
  resources,
  ns: ["translation"],
  defaultNS: "translation",
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
};

i18n.use(initReactI18next).init(config);

export default i18n;
